import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { motion, useAnimation } from "framer-motion";
import { InView, useInView } from "react-intersection-observer";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  jobtitle,
  company,
  location,
  type,
  duration,
  salary,
  description,
  requirements
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="home-about" id="about">
        <div className="container">
        <div className="title">
        <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.15,
                  }}
                  className="backto"
                >
                  <Link to="/live-jobs">Back to jobs</Link>
                </motion.p>
              )}
            </InView> 
        <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.h2
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.25,
                  }}
                >
                  {jobtitle}
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.35,
                  }}
                  className="live-job-sub"
                >
                  {company}
                </motion.p>
              )}
            </InView>
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.45,
                  }}
                  className="live-job-sub highlight"
                >
                  {salary}
                </motion.p>
              )}
            </InView>
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.55,
                  }}
                  className="live-job-sub"
                >
                  {location}
                </motion.p>
              )}
            </InView>
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(-50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.65,
                  }}
                  className="live-job-sub"
                >
                  {type}
                </motion.p>
              )}
            </InView>
          </div>
          
          <div className="about">
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.75,
                  }}
                >
                  {description}
                
                </motion.p>
              )}
            </InView>
            <br />
            <InView threshold={0.2}>
              {({ ref, inView }) => (
                <motion.p
                  ref={ref}
                  initial={{ opacity: 0, transform: "translate(50px)" }}
                  animate={
                    inView
                      ? { opacity: 1, transform: "translate(0px)" }
                      : { opacity: 1 }
                  }
                  transition={{
                    type: "spring",
                    stiffness: 100,
                    duration: 3,
                    delay: 0.85,
                  }}
                >
                  {requirements}
                </motion.p>
              )}
            </InView>
            <InView threshold={0.2}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, transform: "translate(50px)" }}
                animate={
                  inView
                    ? { opacity: 1, transform: "translate(0px)" }
                    : { opacity: 1 }
                }
                transition={{
                  type: "spring",
                  stiffness: 100,
                  duration: 3,
                  delay: 0.25,
                }}
                className="padit"
              >
                <a href="mailto:info@weareopal.digital" target="_blank" className="button-alt">
                  Apply for role
                </a>
              </motion.div>
            )}
          </InView>
          </div>
        </div>
      </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  jobtitle: PropTypes.string,
  company: PropTypes.string,
  location: PropTypes.string,
  type: PropTypes.string,
  duration: PropTypes.string,
  salary: PropTypes.string,
  description: PropTypes.string,
  requirements: PropTypes.string,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        jobtitle={post.frontmatter.jobtitle}
        company={post.frontmatter.company}
        location={post.frontmatter.location}
        type={post.frontmatter.type}
        duration={post.frontmatter.duration}
        salary={post.frontmatter.salary}
        description={post.frontmatter.description}
        requirements={post.frontmatter.requirements}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM, YYYY")
        jobtitle
        templateKey
        date(formatString: "MMMM, YYYY")
        company
        location
        type
        duration
        salary
        description
        requirements
      }
    }
  }
`
